<template>
  <div class="bg-white">
    <q-scroll-area
      class="l-grow bg-primery"
      style="height: calc(100vh - 173px)"
    >
      <q-list class="bg-grey-2" separator>
        <q-item
          :class="item.is_read == 1 ? 'bg-grey-4' : 'bg-blue-3'"
          v-for="item in notificationList"
          :key="item.id"
          @click="proceedNotif(item)"
          clickable
          v-ripple
        >
          <q-item-section avatar>
            <q-avatar color="primary" text-color="white">
              {{ item.initial }}
            </q-avatar>
          </q-item-section>

          <q-item-section>
            <q-item-label>{{ item.pengirim }}</q-item-label>
            <q-item-label caption>{{ item.content }}</q-item-label>
          </q-item-section>

          <q-item-section side>
            <q-item-label>{{
              moment(item.created).format("DD-MMM-YYYY")
            }}</q-item-label>
            <q-item-label>{{
              moment(item.created).format("HH:mm")
            }}</q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-scroll-area>
    <div class="q-pa-lg flex flex-center">
      <q-pagination
        v-model="pagination.currentPage"
        :max="pagination.totalPage"
        input
        input-class="text-orange-10"
        @update:model-value="getNotif"
      />
    </div>

    <q-btn
      class="full-width no-border-radius"
      color="primary"
      label="Mark All As Read"
      @click="markAll"
    ></q-btn>
  </div>
</template>

<script>
import { computed, ref, reactive, inject } from "vue";
import { onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import moment from "moment";

export default {
  setup() {
    const router = useRouter();
    const route = useRoute();
    const $http = inject("$http");
    let pagination = reactive({
      currentPage: 1,
      totalPage: 1,
      rowPerPage: 10,
    });
    let curUser = ref({});

    onMounted(async () => {
      curUser.value = JSON.parse(JSON.stringify(route.meta.user));
      await getNotif();
    });

    let notificationList = ref([]);
    let getNotif = async () => {
      pagination.offset = pagination.rowPerPage * (pagination.currentPage - 1);
      pagination.limit = pagination.rowPerPage;
      pagination.id = curUser.value.id;

      let resp = await $http.post("/notification/list", pagination);
      for (let item of resp.data.rows) {
        item.initial = item.pengirim.split("")[0].toUpperCase();
      }
      notificationList.value = resp.data.rows;
      if (notificationList.value.length > 0) {
        pagination.totalPage = Math.ceil(
          resp.data.total_row / pagination.rowPerPage
        );
      }
    };

    let proceedNotif = async (item) => {
      let payload = {
        id: item.id,
      };
      await $http.post(`/notification/process`, payload);
      router.push(item.router_to);
    };

    let markAll = async () => {
      let payload = {
        id: curUser.value.id,
      };
      await $http.post(`/notification/markall`, payload);
      await getNotif();
    };

    return {
      router,
      route,
      moment,
      notificationList,
      pagination,
      getNotif,
      proceedNotif,
      markAll,
    };
  },
};
</script>
